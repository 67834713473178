
import { IResponse } from '@/common/http'
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
interface ITableData {
  id: number,
  // eslint-disable-next-line camelcase
  name: number,
  intro: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  num: string,
  day: string,
  createtime: string,
  payment: string,
  price: number
}

interface IInvoiceForms {
  [key: string]: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  type: string,
  risetype: string,
  risename: string,
  tax: string,
  name: string,
  mobile: string,
  area: string,
  address: string
}

interface IState {
  page: number,
  pageSize: number,
  total: number,
  tableData: ITableData[] | null,
  invoiceDialog: boolean,
  invoiceForms: IInvoiceForms
}

export default defineComponent({
  name: 'HistoryInvoice',
  setup () {
    const { proxy }: any = getCurrentInstance()
    const state = reactive<IState>({
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: null,
      invoiceDialog: false,
      invoiceForms: {
        out_trade_no: '',
        type: 'mail',
        risetype: '',
        risename: '',
        tax: '',
        name: '',
        mobile: '',
        area: '',
        address: ''
      }
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/invoice/log', {
        params: {
          page: state.page,
          page_size: state.pageSize
        }
      }).then((res: IResponse) => {
        state.total = res.data.count
        state.tableData = res.data.lists
      })
    }
    getTableList()

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList()
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList()
    }

    return {
      ...stateAsRefs,
      getTableList,
      handleSizeChange,
      handleCurrentChange,
      responsively
    }
  }
})
