<template>
  <el-row>
    <el-col>
      <el-card>
        <el-table
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="expand">
            <template #default="props">
              <el-form label-position="left" class="table-expand">
                <el-form-item label="订单号">
                  <span>{{ props.row.out_trade_no }}</span>
                </el-form-item>
                <el-form-item label="发票类型">
                  <span>{{ props.row.type === 'online' ? '线上' : '邮寄' }}</span>
                </el-form-item>
                <el-form-item label="抬头类型">
                  <span>{{ props.row.risetype === 'personal' ? '个人' : '企业' }}</span>
                </el-form-item>
                <el-form-item label="抬头名称">
                  <span>{{ props.row.risename }}</span>
                </el-form-item>
                <el-form-item label="单位税号">
                  <span>{{ props.row.tax }}</span>
                </el-form-item>
                <el-form-item label="收票人">
                  <span>{{ props.row.name }}</span>
                </el-form-item>
                <el-form-item label="联系电话">
                  <span>{{ props.row.mobile }}</span>
                </el-form-item>
                <el-form-item label="所在地区">
                  <span>{{ props.row.area }}</span>
                </el-form-item>
                <el-form-item label="详细地址">
                  <span>{{ props.row.address }}</span>
                </el-form-item>
                <el-form-item v-if="props.row.image" label="电子发票图片">
                  <span><u-image :src="props.row.image"></u-image></span>
                </el-form-item>
                <el-form-item v-if="props.row.express" label="快递单号">
                  <span>{{ props.row.express }}</span>
                </el-form-item>
                <el-form-item label="状态">
                  <span>{{ props.row.state }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column
            prop="out_trade_no"
            label="订单号"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="收票人"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="mobile"
            label="联系电话"
            width="140"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="state"
            label="状态"
            width="140"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="createtime"
            label="申请时间"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="updatetime"
            label="更新时间"
            width="140"
          ></el-table-column>
        </el-table>
      </el-card>
      <el-card class="page__footer">
        <el-pagination
          background
          :small="responsively"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total"
        />
      </el-card>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
interface ITableData {
  id: number,
  // eslint-disable-next-line camelcase
  name: number,
  intro: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  num: string,
  day: string,
  createtime: string,
  payment: string,
  price: number
}

interface IInvoiceForms {
  [key: string]: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  type: string,
  risetype: string,
  risename: string,
  tax: string,
  name: string,
  mobile: string,
  area: string,
  address: string
}

interface IState {
  page: number,
  pageSize: number,
  total: number,
  tableData: ITableData[] | null,
  invoiceDialog: boolean,
  invoiceForms: IInvoiceForms
}

export default defineComponent({
  name: 'HistoryInvoice',
  setup () {
    const { proxy }: any = getCurrentInstance()
    const state = reactive<IState>({
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: null,
      invoiceDialog: false,
      invoiceForms: {
        out_trade_no: '',
        type: 'mail',
        risetype: '',
        risename: '',
        tax: '',
        name: '',
        mobile: '',
        area: '',
        address: ''
      }
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/invoice/log', {
        params: {
          page: state.page,
          page_size: state.pageSize
        }
      }).then((res: IResponse) => {
        state.total = res.data.count
        state.tableData = res.data.lists
      })
    }
    getTableList()

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList()
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList()
    }

    return {
      ...stateAsRefs,
      getTableList,
      handleSizeChange,
      handleCurrentChange,
      responsively
    }
  }
})
</script>

<style lang="scss" scoped>
  .table-expand {
    font-size: 0;
    label {
      width: 90px;
      color: #99a9bf;
    }
    .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 50%;
    }
  }

  .page {
    @mixin cardBody {
      :deep(.el-card__body) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px;
      }
    }
    &__footer {
      margin: 12px 0 0;
      @include cardBody;
    }
  }
</style>
